import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
} from "@shopify/polaris";

import NoDataFound from "./CustomComponents/NoDataFound";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { Link, useNavigate } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import { useSelector } from "react-redux";
import Loader from "../Components/UI/Loader/Loader";
import AddCsvFile from "./UI/AddCsvFile";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
const Channels = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);
  const navigate = useNavigate();

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  // const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
  const [showAddMapsMenuActive, setShowAddMapsMenuActive] = useState(false);
  const [showEditMapsMenuActive, setEditShowAddMapsMenuActive] =
    useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "All",
    "Unpaid",
    "Open",
    "Closed",
    "Local delivery",
    "Local pickup",
  ]);

  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
            {
              type: "rename",
              onAction: () => {},
              onPrimaryAction: async (value) => {
                const newItemsStrings = tabs.map((item, idx) => {
                  if (idx === index) {
                    return value;
                  }
                  return item.content;
                });
                await sleep(1);
                setItemStrings(newItemsStrings);
                return true;
              },
            },
            {
              type: "duplicate",
              onPrimaryAction: async (value) => {
                await sleep(1);
                duplicateView(value);
                return true;
              },
            },
            {
              type: "edit",
            },
            {
              type: "delete",
              onPrimaryAction: async () => {
                await sleep(1);
                deleteView(index);
                return true;
              },
            },
          ],
  }));

  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };
  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];
  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => {};

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
          type: "save-as",
          onAction: onCreateNewView,
          disabled: false,
          loading: false,
        }
      : {
          type: "save",
          onAction: onHandleSave,
          disabled: false,
          loading: false,
        };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  // getItemChecked(selectedResources[0]);
  const [channelUniqueId, setChannelUniqueId] = useState("");
  const [secretKey, setSecretKey] = useState();

  useEffect(() => {
    setChannelUniqueId(channelUniqueId);
    setSecretKey(secretKey);
  }, [channelUniqueId, secretKey]);
  const [selectedChannel, setSelectedChannel] = useState("");

  const addCompetitorsRatesHandler = (id, secret_key, channel_name) => {
    setSelectedChannel(channel_name);
    setSecretKey(secret_key);
    clearSelection();
    setChannelUniqueId(id);
    setShowAddMapsMenuActive(true);
  };

  const editCompetitorsRatesHandler = async (id, secret_key) => {
    setSecretKey(secret_key);
    clearSelection();
    setChannelUniqueId(id);

    try {
      const response = await fetch(
        // {{main}}/api/v1/admin/channels/edit/34
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/channels/edit/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        setEditShowAddMapsMenuActive(true);
        setAddChannelData(data.channel);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }
  const manageButton = <BgOutlineThemeButton children={"Manage"} />;

  const [orders, setOrders] = useState([]);
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  // Fetch Info
  const property_id = useSelector((state) => state.property.value);

  const [loader, setLoader] = useState(true);
  const fetchInfo = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        //{{development}}/api/v1/admin/channels/list
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/channels/list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setOrders(data.data.data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const [properties, setProperties] = useState([]);

  const fetchProperties = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/properties`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setProperties(data.properties);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
    fetchProperties();
  }, []);

  useEffect(() => {
    fetchInfo();
  }, []);

  setTimeout(() => {
    setLoader(false);
  }, 2000);

  const propertiesOptions = properties?.map((property) => {
    return { label: property.property_name, value: property.id };
  });

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const removeAddModalContainer = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  // UseState for forms
  const [addMapsData, setAddMapsData] = useState({
    property_id: "",
    property_name: "",
    meal_plan_name: "",
    channel_unique_id: "",
    property_room_id: "",
    channel_room_id: "",
    property_rate_plan_id: "",
    channel_rate_plan_id: "",
    channel_id: "",
    channel_name: "",

    // competitor_id: channelUniqueId,
  });
  useEffect(() => {
    setAddMapsData((prevData) => ({
      ...prevData,
      channel_name: selectedChannel,
      channel_id: channelUniqueId,
    }));
  }, [selectedChannel, channelUniqueId]);

  console.log(channelUniqueId, "channelUniqueId");
  const rowMarkup = orders.map(
    ({
      id,
      average_rate,
      competitor_name,
      hotel_category,
      channel_name,
      index,
      secret_key,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => navigate(`/channels/${id}`)}
          >
            {<CustomCssComponentTableText innerText={channel_name} />}
          </span>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {<CustomCssComponentTableText innerText={hotel_category} />}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {<CustomCssComponentTableText innerText={average_rate} />}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <button
            style={{
              padding: "5px",
              fontSize: "14px",
              color: "white",
              fontWeight: "500",
              backgroundColor: "#3968ed",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              zIndex: "99999999",
            }}
            onClick={() =>
              addCompetitorsRatesHandler(id, secret_key, channel_name)
            }
          >
            Channel Mapping
          </button>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <button
            style={{
              padding: "5px",
              fontSize: "14px",
              color: "white",
              fontWeight: "500",
              backgroundColor: "#3968ed",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              zIndex: "99999999",
            }}
            onClick={() => editCompetitorsRatesHandler(id, secret_key)}
          >
            Edit
          </button>
        </IndexTable.Cell>
        {/* <IndexTable.Cell>{<CustomCssComponentTableText innerText={manageButton} />}</IndexTable.Cell> */}
      </IndexTable.Row>
    )
  );

  const [selectedPropertyRooms, setSelectedPropertyRooms] = useState(null);

  const [propertyId, setPropertyId] = useState("");
  const onPropertyChangeFunc = (e) => {
    const property_id = e.target.value;
    setPropertyId(property_id);

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/properties/rooms/${e.target.value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setSelectedPropertyRooms(data.rooms);

        if (data.success) {
          setAddMapsData({
            ...addMapsData,
            property_id: property_id,
            property_name: propertiesOptions.find(
              (property) => +property.value === +property_id
            ).label,
          });
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const roomOptions = selectedPropertyRooms?.map((room) => {
    return { label: room.room_name, value: room.id };
  });
  // console.log(roomOptions, "roomOptions");

  const [roomId, setRoomId] = useState("");
  const [selectedRoomRates, setSelectedRoomRates] = useState(null);

  const onRoomChangeFunc = (e) => {
    setRoomId(e.target.value);
    const roomId = +e.target.value;
    e.preventDefault();

    if (addMapsData.channel_unique_id === "") {
      showErrorToast("Please enter the channel ID");
      return;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/properties/rooms/rates/${roomId}/${propertyId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setSelectedRoomRates(data.roomRates);

        if (data.success) {
          setAddMapsData({
            ...addMapsData,
            property_room_id: roomId,
            room_name: roomOptions.find((room) => +room.value === roomId)
              ?.label,
          });
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const onChannelIdChangeBlur = () => {
    const proxyUrl =
      "https://bookingjini.com/pms/hotel-details.php?key=3ac8497f-3a21-4925-b5de-08af72cdc5fd&hotel_id=1953";
    fetch(proxyUrl, {
      method: "GET",
      // mode: "no-cors",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // redirect: "follow",
      // referrerPolicy: "no-referrer",
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectedRoomRates(data.roomRates);
        // if (data.success) {
        //   setAddMapsData({
        //     ...addMapsData,
        //     channel_room_id: 1,
        //   });
        // } else {
        //   showErrorToast(data.message);
        // }
      })
      .catch((error) => {
        // console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const rateOptions = selectedRoomRates?.map((room) => {
    // console.log(room);
    return { label: room.rate_plan_name, value: room.id };
  });

  // console.log(roomOptions);

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
    // console.log("removed");
  };

  // Plan Type Options

  // Plan Name Options

  const [planNameSelected, setNameTypeSelected] = useState("EP");
  const planNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  // Plan Name Options
  const planStatusOptions = [
    { label: "Booked", value: "Booked" },
    {
      label: "Available",
      value: "Available",
    },
  ];

  const [planStatusSelected, setPlanStatusSelected] = useState("Booked");
  const planStatusHandleSelectChange = useCallback(
    (value) => setPlanStatusSelected(value),
    []
  );

  // Plan Type Options
  const roomTypeOptions = [
    { label: "Deluxe", value: "Deluxe" },
    { label: "Super Deluxe", value: "Super Deluxe" },
    { label: "Cottage", value: "Cottage" },
    { label: "Luxury", value: "Luxury" },
    { label: "Executive", value: "Executive" },
    { label: "Family", value: "Family" },
  ];

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  // UseState for forms
  const [addChannelData, setAddChannelData] = useState({
    channel_name: "",
    secret_key: "",
    channel_url: "",
    our_api_key: "",
    our_username: "",
    our_password: "",
    channel_username: "",
    channel_password: "",

    //     'channel_name'
    // 'secret_key'
    // 'channel_url'
    // 'our_api_key'
    // 'our_username'
    // 'our_password'
    // 'channel_username'
    // 'channel_password'
  });

  const setAddItemDataHandleChange = (event) => {
    setAddChannelData({
      ...addChannelData,
      [event.target.name]: event.target.value,
    });
  };
  // console.log(competitorsData);

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    // http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/competitors/delete
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/channel/delete`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    );

    const data = await response.json();
    clearSelection();
    // now do whatever you want with the data
    // console.log(data);
    fetchInfo();
    clearSelection();
    setShowLowerMenuMultipleSelect(false);
    setShowModelMenuActive(false);
  };

  const submitChannelData = (e) => {
    

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/channel/create`, {
      method: "POST",
      body: JSON.stringify(addChannelData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);

        if (data.success) {
          fetchInfo();
          showSuccessToast("Channel added Successfully");
          setShowAddModelMenuActive(false);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleUpdateAddMeals = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/channel/update/${channelUniqueId}`,
      {
        method: "POST",
        body: JSON.stringify(addChannelData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast("Channel Updated Successfully");
          setEditShowAddMapsMenuActive(false);
          fetchInfo();
        } else {
          showErrorToast(data.message);
        }
      });
  };

  // Channel Logo
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileUploadChange = (e) => {
    e.stopPropagation();
    setUploadedFile(e.target.files[0]);
  };

  const fetchChannelLogo = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/media/fetch/channel/logo/${channelUniqueId}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.channel_logo === null) {
          setUploadedFile(null);
        } else {
          const url = data.channel_logo;
          setUploadedFile(url);
        }
      });
  };

  useEffect(() => {
    if (channelUniqueId) {
      fetchChannelLogo();
    }
  }, [channelUniqueId]);

  const addChannelLogo = (event) => {
    event.preventDefault();
    setLoader(true);
    const formData = new FormData();
    formData.append("associated_id", channelUniqueId);
    formData.append("channel_logo", uploadedFile);
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/media/store/channel/logo`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setLoader(false);
        } else {
          setLoader(false);
          showErrorToast("Something Went Wrong.. Please try again...");
        }
      });
  };

  const deleteChannelLogo = async (channelLogoId) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/media/delete/channel/logo/${channelLogoId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    const data = await response
      .json()
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          fetchChannelLogo();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // ==========================================
  const [dataArray, setDataArray] = useState([]);

  const handleAddClick = () => {
    if (addMapsData.property_id === "") {
      showErrorToast("Please select Property ");
      return;
    }
    if (addMapsData.channel_unique_id === "") {
      showErrorToast("Please enter Channel Id ");
      return;
    }

    if (addMapsData.property_room_id === "") {
      showErrorToast("Please select Rooms Types");
      return;
    }

    if (addMapsData.channel_room_id === "") {
      showErrorToast("Please Enter Channel Room Id");
      return;
    }
    if (addMapsData.property_rate_plan_id === "") {
      showErrorToast("please select  Rate Plan Id");
      return;
    }
    if (addMapsData.channel_rate_plan_id === "") {
      showErrorToast("please Enter Channel rate plan Id");
      return;
    }

    setDataArray((prevArray) => [...prevArray, addMapsData]);

    setAddMapsData((prev) => ({
      ...prev,
      property_room_id: "",
      channel_room_id: "",
      property_rate_plan_id: "",
      channel_rate_plan_id: "",
      rate_plan_name: "",
      room_name: "",
    }));
  };

  // console.log(dataArray, "dataArray");

  console.log(addMapsData, "addMapsData");

  console.log(rateOptions, "rateOptions");

  const setAddItemRateDataHandleChange = (event) => {
    if (event.target.name === "property_rate_plan_id") {
      setAddMapsData({
        ...addMapsData,
        [event.target.name]: event.target.value,
        meal_plan_name: rateOptions.find(
          (item) => +item.value === +event.target.value
        ).label,
      });
      return;
    } else {
      setAddMapsData({
        ...addMapsData,
        [event.target.name]: event.target.value,
      });
    }
  };
  const handleSubmitAddMeals = (event) => {
    
    event.preventDefault();

    fetch(`${process.env.REACT_APP_BASE_LOCAL_URL}/api/v1/admin/map/channels`, {
      method: "POST",
      body: JSON.stringify({
        data : dataArray
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setShowAddMapsMenuActive(false);
          // setAddRateData({
          //   rate: "",
          //   rate_date: "",
          // });
        } else {
          showErrorToast(data.message);
        }
      });
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Channels</div>
              <div style={{ display: "flex", gap: "10px" }}>
                {showLowerMenuSingleSelect && (
                  <Button plain destructive onClick={showModelMenu}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon source={DeleteMajor} color="base" />
                      </span>
                      <span>Remove</span>
                    </div>
                  </Button>
                )}

                {showLowerMenuMultipleSelect && (
                  <Button plain destructive onClick={showModelMenu}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon source={DeleteMajor} color="base" />
                      </span>
                      <span>Remove</span>
                    </div>
                  </Button>
                )}

                <BgThemeButton
                  onClick={addModalButtonClickHandler}
                  children={"Add Channel"}
                />
              </div>
            </div>

            {orders && orders.length > 0 ? (
              <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={orders.length}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: "Name" },
                    { title: "Hotel Category" },
                    // { title: "Average Rates" },
                    // { title: "" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>

                {/* Single select */}
                {showLowerMenuSingleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          gap: "20px",
                        }}
                      >
                        <Button plain destructive onClick={showModelMenu}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <Icon source={DeleteMajor} color="base" />
                            </span>
                            <span>Remove</span>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                {/* Multiple select */}
                {showLowerMenuMultipleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Button plain destructive onClick={showModelMenu}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <Icon source={DeleteMajor} color="base" />
                            </span>
                            <span>Remove</span>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </LegacyCard>
            ) : (
              <NoDataFound />
            )}
          </div>

          {/* Edit Button Click */}
          {showEditMapsMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Edit Channel
                    </div>
                    <div
                      onClick={() => setEditShowAddMapsMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Channel Name"
                        name="channel_name"
                        type="text"
                        value={addChannelData.channel_name}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Secret Key"
                        name="secret_key"
                        type="text"
                        value={addChannelData.secret_key}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="URL"
                        name="channel_url"
                        type="text"
                        value={addChannelData.channel_url}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Our Username"
                        name="our_username"
                        type="text"
                        value={addChannelData.our_username}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Our API key"
                        name="our_api_key"
                        type="text"
                        value={addChannelData.our_api_key}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Our Password"
                        name="our_password"
                        type="text"
                        value={addChannelData.our_password}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Channel Username"
                        name="channel_username"
                        type="text"
                        value={addChannelData.channel_username}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Channel Password"
                        name="channel_password"
                        type="text"
                        value={addChannelData.channel_password}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div style={{ marginBottom: "10px" }}>
                    {loader ? (
                      <Loader />
                    ) : (
                      <div style={{ position: "relative" }}>
                        {uploadedFile ? (
                          <>
                            <div className="featureImages_logoPreview_container">
                              <div
                                style={{
                                  width: "100%",
                                  maxHeight: "200px",
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  src={
                                    uploadedFile instanceof File
                                      ? URL.createObjectURL(uploadedFile)
                                      : uploadedFile.src
                                  }
                                  alt="Channel Logo Image"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </div>
                              <div className="featureImages_btnContainer">
                                <button
                                  type="button"
                                  onClick={() =>
                                    deleteChannelLogo(uploadedFile.id)
                                  }
                                  className="featureImages_removeLogobtn"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                  >
                                    <path d="M1 6.94752L7 1" stroke="black" />
                                    <path
                                      d="M7 6.94775L1 1.00023"
                                      stroke="black"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            {!uploadedFile.src && (
                              <BgThemeButton
                                onClick={addChannelLogo}
                                style={{ marginTop: "10px" }}
                              >
                                Upload
                              </BgThemeButton>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="featureImages_uplaodFile_displaytext_container">
                              <p className="featured_image_text">Add Logo</p>
                              <p className="featured_image_text_dragdrop">
                                or drop JPG, PNG images here
                              </p>
                              <input
                                accept="image/*"
                                type="file"
                                id="select-image"
                                onChange={handleFileUploadChange}
                                className="featureImages_upload_file_input"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={(e) => handleUpdateAddMeals(e)}
                        type="submit"
                        children={"Update"}
                      />
                      <Button
                        onClick={() => {
                          setEditShowAddMapsMenuActive(false);
                          setAddChannelData({});
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
          {/* Add Button Click */}
          {showAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <div
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Add Channel
                    </div>
                    <div
                      onClick={() => setShowAddModelMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Channel Name"
                        name="channel_name"
                        type="text"
                        value={addChannelData.channel_name}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Secret Key"
                        name="secret_key"
                        type="text"
                        value={addChannelData.secret_key}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="URL"
                        name="channel_url"
                        type="text"
                        value={addChannelData.channel_url}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Our Username"
                        name="our_username"
                        type="text"
                        value={addChannelData.our_username}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Our API key"
                        name="our_api_key"
                        type="text"
                        value={addChannelData.our_api_key}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Our Password"
                        name="our_password"
                        type="text"
                        value={addChannelData.our_password}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Channel Username"
                        name="channel_username"
                        type="text"
                        value={addChannelData.channel_username}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Channel Password"
                        name="channel_password"
                        type="text"
                        value={addChannelData.channel_password}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={(e) => submitChannelData(e)}
                        type="submit"
                        children={"Add"}
                      />
                      <Button onClick={() => setShowAddModelMenuActive(false)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/*========= channel mapping popup end ========= */}
          {showAddMapsMenuActive && (
            <>
              <div className="bg-backdrop">
                <div
                  className="stock-add-model-container"
                  style={{ width: "850px" }}
                >
                  <div className="flex justify_content_between align_items_center">
                    <div
                      className="add-ota-model-container-heading"
                      style={{ marginBottom: "15px" }}
                    >
                      Mapping
                    </div>
                    <div
                      onClick={() => setShowAddMapsMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        titleName={"Properties"}
                        value={addMapsData.property_id}
                        name="property_id"
                        onChange={(e) => onPropertyChangeFunc(e)}
                        options={propertiesOptions}
                        disabled={dataArray.length > 0 ? true : false}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        titleName="Channel Id"
                        name="channel_unique_id"
                        type="number"
                        value={addMapsData.channel_unique_id}
                        onChange={setAddItemRateDataHandleChange}
                        disabled={dataArray.length > 0 ? true : false}
                      />
                    </div>
                  </div>
                  

                  {roomOptions && roomOptions.length > 0 && (
                    <div className="flex flex_gap_10">
                      <VerticalInputSelectCustomCopy
                        titleName={"Rooms"}
                        name="property_room_id"
                        options={roomOptions}
                        value={addMapsData.property_room_id}
                        onChange={(e) => onRoomChangeFunc(e)}
                        style={{ width: "200px" }}
                      />

                      <VerticalInputFieldCustom
                        titleName="Channel Room Id"
                        name="channel_room_id"
                        type="number"
                        value={addMapsData.channel_room_id}
                        onChange={setAddItemRateDataHandleChange}
                        style={{ width: "150px" }}
                      />
                      {rateOptions && rateOptions.length > 0 && (
                        <div className="flex flex_gap_10 align_items_end">
                          <VerticalInputSelectCustomCopy
                            titleName={"Room Rate"}
                            options={rateOptions}
                            name={"property_rate_plan_id"}
                            value={addMapsData.property_rate_plan_id}
                            onChange={setAddItemRateDataHandleChange}
                            style={{ width: "200px" }}
                          />

                          <VerticalInputFieldCustom
                            titleName="Channel Rate Id"
                            name="channel_rate_plan_id"
                            type="number"
                            value={addMapsData.channel_rate_plan_id}
                            onChange={setAddItemRateDataHandleChange}
                            style={{ width: "150px" }}
                          />

                          <button
                            onClick={handleAddClick}
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                              border: "1px solid #3968ed",
                              borderRadius: "2px",
                              padding:"2px",
                              display:"grid",
                              placeItems:"center",
                              width:"fit-content",
                              height:"fit-content",
                            }}
                          >
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              
                            >
                              <path
                                d="M11 0H9V9H0V11H9V20H11V11H20V9H11V0Z"
                                fill="#3968ed"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  {dataArray.length > 0 ? (
                    <>
                      <div style={{ margin: "10px  0" }}>
                        {dataArray.map((item, index) => {
                          return (
                            <div
                              className="flex flex_gap_10 align_items_end"
                              key={index}
                            >
                              <VerticalInputFieldCustom
                                value={item.room_name}
                                disabled={true}
                                style={{ width: "200px" }}
                              />
                              <VerticalInputFieldCustom
                                value={item.channel_room_id}
                                disabled={true}
                                style={{ width: "150px" }}
                              />

                              <VerticalInputFieldCustom
                                value={item.meal_plan_name}
                                disabled={true}
                                style={{ width: "200px" }}
                              />
                              <VerticalInputFieldCustom
                                value={item.channel_rate_plan_id}
                                disabled={true}
                                style={{ width: "150px" }}
                              />

                              <div>
                                <button
                                  onClick={() =>
                                    setDataArray(
                                      dataArray.filter(
                                        (_, dataIndex) => dataIndex !== index
                                      )
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                    background: "transparent",
                                    border: "none",
                                  }}
                                >
                                  <svg
                                    width="14"
                                    height="16"
                                    viewBox="0 0 14 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M10.2001 3.2H12.6001C13.0419 3.2 13.4001 3.55817 13.4001 4L13.4001 4.8H0.600098L0.600119 4C0.600119 3.55817 0.958291 3.2 1.40012 3.2H3.80012V1.2C3.80012 0.537258 4.33738 0 5.00012 0H9.00012C9.66286 0 10.2001 0.537259 10.2001 1.2V3.2ZM5.40012 1.6V3.2H8.60012V1.6H5.40012Z"
                                      fill="#E03838"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M1.40012 6.4H12.6001V14.8C12.6001 15.4627 12.0629 16 11.4001 16H2.60012C1.93738 16 1.40012 15.4627 1.40012 14.8V6.4ZM4.6001 8.8H3.0001V13.6H4.6001V8.8ZM7.8001 8.8H6.2001V13.6H7.8001V8.8ZM9.4001 8.8H11.0001V13.6H9.4001V8.8Z"
                                      fill="#E03838"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : null}

                  <div
                    className="flex flex_gap_10"
                    style={{ margin: "15px 0" }}
                  >
                    <BgThemeButton
                      onClick={(e) => handleSubmitAddMeals(e)}
                      children={"Add"}
                      disabled={dataArray.length > 0 ? false : true}
                    />
                    <Button onClick={() => setShowAddMapsMenuActive(false)}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
          {/*========= channel mapping popup end ========= */}


          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={deleteData} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default Channels;
