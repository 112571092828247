import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import InfiniteLoading from '../InfiniteLoading/InfiniteLoading'
import styles from "./ListingPropertyTargets.module.scss"
import { CustomShopifyIndexTable, CustomShopifyIndexTableCell, CustomShopifyIndexTableRow } from '../CustomShopifyIndexTable/CustomShopifyIndexTable'
import AddTargetPageNew from '../../../Pages/AddTargetPageNew'
import { getDateByMonth } from '../../../assets/utils'
import Pagination from '../NewCustomComp/pagination/Pagination'
import { SvgBack, SvgDelete, SvgEdit, SvgSettings } from '../../svgIcons/Icons'
import { PrimaryButton } from '../NewCustomComp/Buttons/Buttons'
import { showSuccessToast } from '../../../assets/toastUtils'
import { PrimaryInput } from '../NewCustomComp/Inputs/Inputs'

const ListingPropertyTargets = () => {
  const pathname = useParams()
  const { propertyId } = pathname

  const [listTargets, setListTargets] = useState("");
  const [nextPage, setNextPage] = useState(null);
  const [addTarget, setAddTarget] = useState(false)

  const handleAddTarget = () => {
    setAddTarget(!addTarget)
  }


  const fetchListTargets = async (nextPage) => {
    const url = nextPage ? `${process.env.REACT_APP_BASE_URL}/api/v1/admin/property-target-listing?property_id=${propertyId}&page=${nextPage}` : `${process.env.REACT_APP_BASE_URL}/api/v1/admin/property-target-listing?property_id=${propertyId}`

    const requestOptions = {
      method: "POST",
      redirect: "follow"
    };
    try {
      const response = await fetch(
        url,
        requestOptions
      );
      const result = await response.json();

      if (result.success) {
        setListTargets(result)
        setNextPage(result.property_targets_list.next_page_url)
      }

    } catch (error) {

    }

  }

  useEffect(() => {
    if (propertyId) {
      fetchListTargets()
    }
  }, []);

  // const fetchInfiniteListing = async (nextPageUrl) => {

  //   const requestOptions = {
  //     method: "POST",
  //     redirect: "follow"
  //   };
  //   try {
  //     const response = await fetch(
  //       nextPageUrl,
  //       requestOptions
  //     );
  //     const result = await response.json();

  //     if (result.success) {
  //       setListTargets(result)
  //       setNextPage(result.property_targets_list.next_page_url)
  //     }

  //   } catch (error) {

  //   }

  // }

  const handlePageChange = (pageNumber) => {
    fetchListTargets(pageNumber)
  }

  // Settings 
  const [settings, setSettings] = useState(false)
  const handleSettings = () => {
    setSettings(!settings)
  }
  // Switch Form
  const [switchForm, setSwitchForm] = useState(false)
  const handleSwitch = () => {
    setSwitchForm(!switchForm)
  }

  // Setting List Api
  const [settingsData, setSettingsData] = useState([""])
  const fetchPropertySettings = async () => {

    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/property-settings`, requestOptions)
      const data = await response.json();
      if (data.success) {
        setSettingsData(data.data)
      }

    } catch (error) {
      console.error(error)
    }
  }

  // Settings Add/Update Api
  const [addData, setAddData] = useState({
    property_margin: "",
    buffer_margin: "",
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddData((prevData) => ({
      ...prevData,
      [name]: value, // Update the specific input field based on its name
    }));
  };

  const AddSettings = async (e) => {
    e.preventDefault()
    const requestOptions = {
      method: "POST",
      redirect: "follow"
    };
    try {
      const url = addData.id
        ? `${process.env.REACT_APP_BASE_URL}/api/v1/admin/property-settings?property_margin=${addData.property_margin}&buffer_margin=${addData.buffer_margin}&id=${addData.id}`
        : `${process.env.REACT_APP_BASE_URL}/api/v1/admin/property-settings?property_margin=${addData.property_margin}&buffer_margin=${addData.buffer_margin}`
      const response = await fetch(url, {
        ...requestOptions,
        body: JSON.stringify({
          property_margin: addData.property_margin,
          buffer_margin: addData.buffer_margin,
        }),
      });

      const data = await response.json();
      if (data.success) {
        showSuccessToast(addData.id ? "Settings Updated Successfully" : "Settings Added Successfully");
        handleSwitch();
        fetchPropertySettings();
      }
    } catch (error) {
      console.log(error)
    }
  }

  // Settings Delete Api
  const deleteSettings = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "ids": [
        id
      ]
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/property-settings`, requestOptions);
      const data = await response.json();
      if (data.success) {
        fetchPropertySettings()
        showSuccessToast('Settings Deleted')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='main_container'>

      <div className={styles.whiteWrapper}>
        {/* {listTargets.map((item, index) => */}
        <div className={styles.totalData} >
          <h3> <strong>Total Yearly Targets :</strong> {listTargets?.targeted_yearly_earning}</h3>
          <h3><strong>Total Expanse :</strong>  {listTargets?.total_expense}</h3>
          <h3><strong>Total No. of Rooms :</strong>   {listTargets?.total_rooms}</h3>
          <h3><strong>Profit Margin (%) :</strong>   </h3>
        </div>
        {/* )} */}
        {/* <div onClick={handleAddTarget} className={styles.targetBtn}>
          Add Targets
        </div> */}
        <div onClick={() => {
          handleSettings();
          fetchPropertySettings();
        }} className={`${styles.settingsIcon} pointer`}>
          <SvgSettings />
        </div>
        {settings && (
          <div className='bg-backdrop'>
            <div className={styles.settingsMain}>
              <div className={`${styles.settingList} ${switchForm ? styles.listHide : styles.listShow}`}>
                <div className='flex align_items_center justify_content_between'>
                  <div className='flex align_items_center flex_gap_10'>
                    <span className='pointer' onClick={handleSettings}>
                      <SvgBack />
                    </span>
                    <h4>Settings</h4>
                  </div>
                  <PrimaryButton size={"medium"} onClick={handleSwitch} title={"Add"} />
                </div>
                <table className={styles.settingsWrapper}>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Buffer</th>
                      <th>Property Margin</th>
                    </tr>
                  </thead>
                  <tbody>
                    {settingsData && settingsData.length > 0 && settingsData.map((items, index) =>
                      <tr key={index} className={styles.settingsTableTr}>
                        <td>{index + 1}</td>
                        <td className={styles.settingTableTd}>{items.buffer_margin}</td>
                        <td>{items.property_margin}</td>
                        <td>
                          <span className='pointer' onClick={() => {
                            setAddData({
                              id: items.id,
                              property_margin: items.property_margin,
                              buffer_margin: items.buffer_margin
                            });
                            handleSwitch()
                          }}>
                            <SvgEdit />
                          </span>
                        </td>
                        <td>
                          <span className='pointer' onClick={() => deleteSettings(items.id)}>
                            <SvgDelete />
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className={`${styles.settingsAdd} ${switchForm ? styles.addShow : styles.addHide}`}>
                <div className='flex align_items_center flex_gap_10 mb-10'>
                  <span  className='pointer' onClick={() => {
                    handleSwitch();
                    setAddData({ property_margin: "", buffer_margin: "" })
                  }}>
                    <SvgBack />
                  </span>
                  <h4>Add Settings</h4>
                </div>
                <div>
                  <PrimaryInput onChange={handleChange} type={"number"} name={"buffer_margin"} value={addData.buffer_margin} size={"medium"} titleName={"Buffer"} />
                </div>
                <div>
                  <PrimaryInput onChange={handleChange} type={"number"} name={"property_margin"} value={addData.property_margin} size={"medium"} titleName={"Property Margin"} />
                </div>
                <div>
                  <PrimaryButton onClick={(e) => AddSettings(e)} size={"medium"} title={"Add"} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div style={{ background: "#fff", padding: "20px" }}>

        {listTargets && listTargets.property_targets_list.data.length > 0 && (<>
          <CustomShopifyIndexTable
            headings={[
              "Date",
              "Trg. ADR",
              "Trg. Occ",
              "Trg. RevPar"
            ]}
            style={{ border: "1px solid #ccc" }}
          >
            {listTargets?.property_targets_list?.data?.length > 0 && listTargets?.property_targets_list?.data?.map((items, index) => {
              return (
                <>
                  <CustomShopifyIndexTableRow>
                    <CustomShopifyIndexTableCell style={{
                      borderBottom:
                        index !== listTargets?.property_targets_list?.data?.length - 1 && "1px solid #ccc",
                    }}>
                      {getDateByMonth(items?.date)}
                    </CustomShopifyIndexTableCell>


                    <CustomShopifyIndexTableCell style={{
                      borderBottom:
                        index !== listTargets?.property_targets_list?.data?.length - 1 && "1px solid #ccc",
                    }}>
                      {items?.target_ar}
                    </CustomShopifyIndexTableCell>

                    <CustomShopifyIndexTableCell style={{
                      borderBottom:
                        index !== listTargets?.property_targets_list?.data?.length - 1 && "1px solid #ccc",
                    }}>
                      {items?.target_occupancy}
                    </CustomShopifyIndexTableCell>

                    <CustomShopifyIndexTableCell style={{
                      borderBottom:
                        index !== listTargets?.property_targets_list?.data?.length - 1 && "1px solid #ccc",
                    }}>
                      {items?.revpar}
                    </CustomShopifyIndexTableCell>
                  </CustomShopifyIndexTableRow>
                </>
              )
            }
            )}
          </CustomShopifyIndexTable>

          <div className="flex justify_content_center align_items_center mt-20">
            <Pagination type={"advance"} totalPages={Number(listTargets.property_targets_list.last_page)} currentPage={listTargets.property_targets_list.current_page} onPageChange={handlePageChange} />

          </div>
        </>
        )}
      </div>



      {addTarget && (
        <div className='bg-backdrop'>
          <div className={`${styles.popAddTargets} ${addTarget ? styles.show : styles.hide}`}>
            <AddTargetPageNew closePopup={handleAddTarget} refreshListApi={fetchListTargets} />
          </div>
        </div>
      )}

    </div>
  )
}

export default ListingPropertyTargets
