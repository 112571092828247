import CustomToggleButton from "./UI/CustomToggleButton";
import { useParams } from "react-router-dom";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import AsignPropertyTableData from "./UI/AsignPropertyTableData";
import NoDataFound from "../Components/CustomComponents/NoDataFound";
// table
import {
  Button,
  Icon,
  IndexTable,
  LegacyCard,
  Page,
  Select,
  TextField,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { Link, useNavigate } from "react-router-dom";
// import { BgThemeButton } from "./Buttons";
import { useDispatch } from "react-redux";
import {
  BgOutlineThemeButton,
  BgOutlineWhite,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "./UI/Buttons";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import Loader from "./UI/Loader/Loader";

const ToggleActiveDisable = (props) => {
  const [isToggled, setIsToggled] = useState(props.status === "enable");
  const [beddingFinalValue, setBeddingFinalValue] = useState("");
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  // const property_id = useSelector((state) => state.property.value);

  const handleToggle = useCallback(() => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  }, []);

  const handleToggleStatus = async () => {
    if (isToggled) {
      await handleDisable();
    } else {
      await handleEnable();
    }
  };

  const handleDisable = async () => {
    const userId = props.userId;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/account/disable/1`,
        {
          method: "POST",
          body: JSON.stringify({ user_id: userId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setIsToggled(false);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const handleEnable = async () => {
    const userId = props.userId;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/account/enable/1`,
        {
          method: "POST",
          body: JSON.stringify({ user_id: userId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
        setIsToggled(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  useEffect(() => {
    if (isToggled === false) {
      setBeddingFinalValue("disabled");
    } else {
      setBeddingFinalValue("enabled");
    }
  }, [isToggled]);

  return (
    <div className="flex align_items_center">
      <span className="toggle_text">{props.status}</span>
      <label className="toggle-container">
        <input
          name="extra_bedding"
          type="checkbox"
          checked={isToggled}
          onChange={handleToggle}
          className="toggle-checkbox-custom"
        />
        <span className="toggle-slider" onClick={handleToggleStatus}></span>
      </label>
    </div>
  );
};

const SingleCustomer = () => {
  const CustomToggleButtonHandler = () => {};

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
  const [syncPopup, setSyncPopup] = useState(false);

  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const navigate = useNavigate();
  const bookingDetails = [
    {
      id: 1,
      tag: "Hotel",
      paymentStatus: "paid",
      day: "January 28, 2022",
      time: "3:10",
      bookedFrom: "MakeMyTrip",
      price: 450,
      url: "/",
    },
    {
      id: 2,
      tag: "Hotel",
      paymentStatus: "Unpaid",
      day: "January 28, 2022",
      time: "4:10",
      bookedFrom: "MakeMyTrip",
      price: 450,
      url: "/",
    },
    {
      id: 3,
      tag: "Hotel",
      paymentStatus: "paid",
      day: "January 28, 2022",
      time: "5:10",
      bookedFrom: "Goibibo",
      price: 450,
      url: "/",
    },
  ];

  let { customerId } = useParams();
  // console.log(customerId);

  const [singleUserDetails, setSingleUserDetails] = useState(null);
  // console.log(singleUserDetails);

  const fetchInfo = async () => {
    // /api/v1/admin/customers/details?account_id=1
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/customers/details?account_id=${customerId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      // if (data.message === "Unauthorised.") {
      //   navigate("/");
      // }

      // setOrders({
      //   ...orders,
      //   url:"/test"
      // })
      // console.log(data.details);
      setSingleUserDetails(data.details);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  // UseState for forms
  const [asignOwnerDate, setAsignOwnerDate] = useState({
    name: "",
    email: "",
    property_id: "",
  });
  const [syncOwnerData, setSyncOwnerData] = useState({
    startDate: "",
    endDate: "",
    property_id: "",
  });

  const setAddItemDataHandleChange = (event) => {
    setAsignOwnerDate({
      ...asignOwnerDate,
      [event.target.name]: event.target.value,
    });
  };
  const setAddItemDataHandleChangeSync = (event) => {
    setSyncOwnerData({
      ...syncOwnerData,
      [event.target.name]: event.target.value,
    });
  };

  const roomTypeOptions = singleUserDetails?.properties?.map((property) => {
    // console.log(roomType);
    return { label: property.property_name, value: property.id };
  });
  // console.log(asignOwnerDate);

  const handleAsignOwnerData = (event) => {
    event.preventDefault();
    // /api/v1/admin/property/assign
    console.log(asignOwnerDate);
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/property/assign`, {
      method: "POST",
      body: JSON.stringify({ ...asignOwnerDate }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        if (data.success) {
          fetchInfo();
          showSuccessToast(data.message);

          navigate("/customers");
        } else {
          showErrorToast("Please enter details carefully");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const handleAsignOwnerDataSync = (event) => {
    event.preventDefault();
    // /api/v1/admin/property/assign
    console.log(asignOwnerDate);
    // fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/hotel-center/push/rates`, {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/hotel-center/push/property-data`,
      {
        method: "POST",
        body: JSON.stringify({
          hotelId: syncOwnerData.property_id,
          // startDate: syncOwnerData.startDate,
          // endDate: syncOwnerData.endDate,
          partnerCode: "revcatalyst_technologies_pvt_ltd_1149580311",
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        if (data.success) {
          fetchInfo();
          showSuccessToast(data.message);

          navigate("/customers");
        } else {
          showErrorToast("Please enter details carefully");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [
    showAddCategoriesLowerMenuActive,
    setShowAddCategoriesLowerMenuActive,
  ] = useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  // const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const properties = [
    {
      id: "General Agents",
      category: "General Agents",
      discount: "10",
    },
    {
      id: "Preferred Agents",
      category: "Preferred Agents",
      discount: "15",
    },
    {
      id: "Exclusive Agents",
      category: "Exclusive Agents",
      discount: "20",
    },
    {
      id: "Special Agents",
      category: "Special Agents",
      discount: "25",
    },
  ];
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [property, setProperty] = useState([]);

  const fetchOwnerDetailsInfo = async (id) => {
    setShowPropertyListingModal(true);
    console.log(id, "propertyId");
    // console.log(userToken.token, "token");
    // if (!id) {
    //   navigate("/login");
    // }
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/property/staff/list?property_id=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          console.log(data);
          setProperty(data.data);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        }
      });

      // setOrders(data.rooms);
      // console.log(data.rooms);
    } catch (error) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      console.error(error);
    }
  };

  // useEffect(() => {
  // if (property_id) {
  // fetchOwnerDetailsInfo();
  // }
  // }, []);

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(property);

  const addCategoriesRowMarkup = property.map(
    ({ id, email, status, index, name }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          <CustomCssComponentTableText innerText={name} /> 
        </IndexTable.Cell>
        <IndexTable.Cell>{email}</IndexTable.Cell>
        <IndexTable.Cell>
          <ToggleActiveDisable
            positive={"Enable"}
            negative={"Disable"}
            status={status}
            userId={id}
          />
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );
  useEffect(() => {
    if (selectedResources.length === 1) {
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const [deletedIds, setDeletedIds] = useState(null);

  const currentDate = new Date().toISOString().split("T")[0];

  const showModelMenu = (id) => {
    const deletedIds = {
      ids: [id],
    };
    setDeletedIds(deletedIds);
    setShowModelMenuActive(!showModelMenuActive);
  };

  const deleteData = async (id) => {
    // clearSelection();
    console.log(deletedIds);
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/properties/delete`,

      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    );

    const data = await response
      .json()
      .then((data) => {
        if (data.success) {
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
          window.location.reload();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        showErrorToast("An error occurred while processing your request...!");
      });
  };

  const [showPropertyListingModal, setShowPropertyListingModal] =
    useState(false);
  return (
    <div className="agent-full-container">
      <div
        style={{
          // paddingLeft: "48px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "180px",
          paddingRight: "200px",
        }}
      >
        <div>
          <Page
            backAction={{ content: "Products", url: `/customers` }}
            title={singleUserDetails?.account_name}
            compactTitle
            subtitle={singleUserDetails?.billing_email}
          ></Page>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <div>
            {/* <button
            style={{
              padding: "5px",
              fontSize: "14px",
              color: "white",
              fontWeight: "500",
              backgroundColor: "#3968ed",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/targets/${customerId}`)}
          >
            Targets
          </button> */}
            <BgThemeButton
              onClick={() => navigate(`/customers/addproperty/${customerId}/`)}
            >
              Add Property
            </BgThemeButton>
          </div>

          <div onClick={(e) => setShowAddModelMenuActive(true)}>
            <BgThemeButton children={"Asign Owner"} />
          </div>
          <div onClick={(e) => setSyncPopup(true)}>
            <BgThemeButton children={"Sync With Google Center"} />
          </div>
        </div>
      </div>

      <div className="agent-full-container-div">
        <div className="agent-full-container-left">
          <div className="agent-full-container-left-div1">
            <div style={{ width: "280px", borderRight: "0.5px solid #CCC" }}>
              <span>Total Property</span>
              <div>{singleUserDetails?.properties.length}</div>
            </div>
            <div style={{ marginLeft: "20px" }}>
              <span>Property Limit</span>
              <div>{singleUserDetails?.property_limits}</div>
            </div>
          </div>

          <div className="agent-full-container-left-div2">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="heading">All Property</div>
              <div></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "space-between",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {singleUserDetails?.properties?.length < 1 ? (
                <div>No Property Added</div>
              ) : (
                <>
                  {singleUserDetails?.properties?.map((item) => {
                    // console.log(item);
                    return (
                      <div className="booking-details-div">
                        <div>
                          <div className="booking-details-div-tag">
                            <div
                              onClick={() => fetchOwnerDetailsInfo(item.id)}
                              style={{ fontSize: "15px", cursor: "pointer" }}
                            >
                              {item.property_name}
                            </div>

                            <div></div>
                          </div>

                          {showPropertyListingModal && (
                            <div className="bg-backdrop">
                              {loader ? (
                                <Loader />
                              ) : (
                                <section className="agent-add-categories-model-container">
                                  <LegacyCard>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>
                                        <Page
                                          backAction={{
                                            content: "Products",
                                            url: `/singlecustomer/${customerId}`,
                                          }}
                                          title="Owners"
                                          compactTitle
                                        ></Page>
                                      </div>
                                      {/* <div>
                                  <BgThemeButton
                                    onClick={() =>
                                      setShowAddCategoriesLowerMenuActive(true)
                                    }
                                    children={" Add Categories"}
                                  />
                                </div> */}
                                    </div>
                                    <div
                                      style={{
                                        padding: "10px",
                                      }}
                                    >
                                      {properties && properties.length > 0 ? (
                                        <IndexTable
                                          resourceName={resourceName}
                                          itemCount={property.length}
                                          selectedItemsCount={
                                            allResourcesSelected
                                              ? "All"
                                              : selectedResources.length
                                          }
                                          onSelectionChange={
                                            handleSelectionChange
                                          }
                                          headings={[
                                            { title: "Owner Name" },
                                            { title: "Owner Email" },
                                            { title: "Status" },
                                          ]}
                                          selectable={false}
                                        >
                                          {addCategoriesRowMarkup}
                                        </IndexTable>
                                      ) : (
                                        <NoDataFound />
                                      )}
                                    </div>

                                    {/* Single select */}
                                    {showLowerMenuSingleSelect && (
                                      <div className=" room-type-bottom-container">
                                        <div className="room-type-bottom-container-div">
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              width: "100%",
                                              gap: "20px",
                                            }}
                                          >
                                            <Button
                                              plain
                                              destructive
                                              onClick={showModelMenu}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <span>
                                                  <Icon
                                                    source={DeleteMajor}
                                                    color="base"
                                                  />
                                                </span>
                                                <span>Remove</span>
                                              </div>
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {/* Multiple select */}
                                    {showLowerMenuMultipleSelect && (
                                      <div className="room-type-bottom-container">
                                        <div className="room-type-bottom-container-div">
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              width: "100%",
                                            }}
                                          >
                                            <Button
                                              plain
                                              destructive
                                              onClick={showModelMenu}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <span>
                                                  <Icon
                                                    source={DeleteMajor}
                                                    color="base"
                                                  />
                                                </span>
                                                <span>Remove</span>
                                              </div>
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {/* Add Button Click */}
                                    {showAddCategoriesLowerMenuActive && (
                                      <div className=" room-type-bottom-container">
                                        <div className="room-type-bottom-container-div">
                                          {/* <div>
                                    <Button plain destructive>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span></span>
                                        <span>Add</span>
                                      </div>
                                    </Button>
                                  </div> */}
                                          <form
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              width: "100%",
                                            }}
                                          >
                                            <div className="room-type-bottom-div">
                                              <div>Enter Name</div>
                                              <div style={{ width: "150px" }}>
                                                <TextField
                                                  className="textfield"
                                                  focused={false}
                                                  autoComplete="off"
                                                />
                                              </div>
                                              <div className="room-type-bottom-div">
                                                <div>Discount Amount</div>
                                                <div style={{ width: "100px" }}>
                                                  <TextField
                                                    className="textfield"
                                                    focused={false}
                                                    autoComplete="off"
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                            <div
                                              style={{
                                                color: "#3968ED",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "10px",
                                              }}
                                            >
                                              <Button outline monochrome>
                                                Add
                                              </Button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    )}
                                  </LegacyCard>
                                </section>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="booking-details-div-money">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <button
                              style={{
                                padding: "5px",
                                fontSize: "14px",
                                color: "white",
                                fontWeight: "500",
                                backgroundColor: "#3968ed",
                                borderRadius: "5px",
                                border: "none",
                                cursor: "pointer",
                              }}
                              onClick={() => navigate(`/targets/${item.id}?${item.property_name.replaceAll(" ","-")}`)}
                              // onClick={() => navigate(`/targets/${item.id}`)}
                            >
                              Targets
                            </button>

                            {/* <BgOutlineThemeButton
                                style={{ height: "25px" }}
                                children={"Add Targets"}
                                onClick={() =>
                                  navigate(`/addtargets/${item.id}`)
                                }
                              /> */}
                            <BgOutlineThemeButton
                              style={{ height: "25px" }}
                              children={"MMT Rates"}
                              onClick={() =>
                                navigate(`/propertyrates/${item.id}`)
                              }
                            />
                            {/* <div style={{ cursor: "no-drop" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M11.3919 1.98174L14.0182 4.60884L14.4562 4.1707C15.1813 3.44536 15.1813 2.26952 14.4562 1.54418C13.7308 0.818608 12.5547 0.818608 11.8294 1.54418L11.3919 1.98174Z"
                                  fill="#3968ED"
                                />
                                <path
                                  d="M12.8124 5.81229L10.1861 3.18518L1.85249 11.5201L1 15L4.47873 14.1472L12.8124 5.81229Z"
                                  fill="#3968ED"
                                />
                              </svg>
                            </div> */}
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => showModelMenu(item.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                                  fill="#E03838"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                                  fill="#E03838"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="agent-full-container-right">
          <div className="agent-full-container-right-div1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "18px",
              }}
            >
              <div
                style={{
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Customer
              </div>
              <div></div>
              {/* <div>Edit</div> */}
            </div>
            <div
              style={{
                color: "#333",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              <div>Contact Information</div>
              <div
                style={{
                  fontWeight: "500",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                {singleUserDetails?.billing_phone}
              </div>
              <div style={{ fontWeight: "500", color: "#3968ED" }}>
                {singleUserDetails?.billing_email}
              </div>
            </div>
          </div>
          <div className="agent-full-container-right-div1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "18px",
              }}
            >
              <div
                style={{
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Business Details
              </div>
              <div></div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    color: "#888",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginBottom: "8px",
                  }}
                >
                  Company Name
                </div>
                <div>{singleUserDetails?.company_name}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    color: "#888",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginBottom: "8px",
                  }}
                >
                  GST NO.
                </div>
                <div>{singleUserDetails?.company_gst}</div>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    color: "#888",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginBottom: "8px",
                  }}
                >
                  Plan Type
                </div>
                <div>Travel Agency</div>
              </div> */}
            </div>
          </div>
          {/* <div className="agent-full-container-right-div1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "18px",
              }}
            >
              <div
                style={{
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Agent Category
              </div>
              <div></div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    color: "#333",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  General Agent
                </div>
                <div
                  style={{
                    color: "#888",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginBottom: "8px",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur. Malesuada iaculis nibh
                  id mattis semper eleifend iaculis vulputate amet. Nunc eget
                  morbi enim facilisis urna. Volutpat risus iaculis tellus sit
                  in. Neque nibh dui aliquet scelerisque posuere enim.
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="agent-full-container-right-div1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "18px",
              }}
            >
              <div
                style={{
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Temporary Booking
              </div>
              <div></div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <div>
                <CustomToggleButton func={CustomToggleButtonHandler} />
              </div>
            </div>
          </div> */}
          {/* <div className="agent-full-container-right-div1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "18px",
              }}
            >
              <div
                style={{
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Assign Hotels
              </div>
              <div></div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <div>
                <CustomToggleButton />
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div>
        {showAddModelMenuActive && (
          <>
            <div className="bg-backdrop">
              <form
                onSubmit={(e) => handleAsignOwnerData(e)}
                style={{ width: "700px" }}
                className="stock-add-model-container"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <div className="add-ota-model-container-heading">
                    Asign Owner
                  </div>
                  <div
                    onClick={() => setShowAddModelMenuActive(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon source={CancelMajor} color="base" />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Owner Name"
                      name="name"
                      // type="number"
                      value={asignOwnerDate.name}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputSelectCustomCopy
                      options={roomTypeOptions}
                      titleName="Select Property"
                      name="property_id"
                      value={asignOwnerDate.property_id}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Email"
                      name="email"
                      // type="email"
                      value={asignOwnerDate.email}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton type="submit" children={"Asign"} />
                    <Button onClick={() => setShowAddModelMenuActive(false)}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
      <div>
        {syncPopup && (
          <>
            <div className="bg-backdrop">
              <form
                onSubmit={(e) => handleAsignOwnerDataSync(e)}
                style={{ width: "700px" }}
                className="stock-add-model-container"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <div className="add-ota-model-container-heading">Sync</div>
                  <div
                    onClick={() => setSyncPopup(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon source={CancelMajor} color="base" />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputSelectCustomCopy
                      required={true}
                      options={roomTypeOptions}
                      titleName="Select Property"
                      name="property_id"
                      value={syncOwnerData.property_id}
                      onChange={setAddItemDataHandleChangeSync}
                    />
                  </div>

                  {/* <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Start Date"
                      name="startDate"
                      type="date"
                      value={syncOwnerData.startDate}
                      onChange={setAddItemDataHandleChangeSync}
                    />
                  </div> */}
                  {/* <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="End Date"
                      name="endDate"
                      type="date"
                      value={syncOwnerData.endDate}
                      onChange={setAddItemDataHandleChangeSync}
                      min={syncOwnerData.startDate}
                    />
                  </div> */}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "10px",
                    }}
                  >
                    {syncOwnerData.property_id ? (
                      <BgThemeButton type="submit" children={"Sync"} />
                    ) : (
                      <BgThemeButtonDisabled type="submit" children={"Sync"} />
                    )}
                    <Button onClick={() => setSyncPopup(false)}>Cancel</Button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                >
                  Discard
                </Button>
                <Button onClick={deleteData} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleCustomer;
