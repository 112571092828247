export const SvgMinus = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
        >
            <g clipPath="url(#clip0_1270_876)">
                <path
                    d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                    stroke="#3968ED"
                ></path>
                <path
                    d="M10.5866 7H3.41333"
                    stroke="#3968ED"
                    strokeMiterlimit="10"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1270_876">
                    <rect width="14" height="14" fill="white"></rect>
                </clipPath>
            </defs>
        </svg>
    )
}

export const SvgPlus = () => {
    return (
        <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_6065_11193)">
                <path
                    d="M2.6811 1H11.3189C12.5235 1 13.5 1.97651 13.5 3.1811V11.8189C13.5 13.0235 12.5235 14 11.3189 14H2.6811C1.47651 14 0.5 13.0235 0.5 11.8189V3.1811C0.5 1.97651 1.47651 1 2.6811 1Z"
                    stroke="#3968ED"
                />
                <path
                    d="M10.5868 7.5H3.41357"
                    stroke="#3968ED"
                    strokeMiterlimit="10"
                />
                <path
                    d="M7 3.91344L7 11.0867"
                    stroke="#3968ED"
                    strokeMiterlimit="10"
                />
            </g>
            <defs>
                <clipPath id="clip0_6065_11193">
                    <rect
                        width="14"
                        height="14"
                        fill="white"
                        transform="translate(0 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export const SvgCancel = () => {

    return (

        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_963_18)">
                <path d="M16.4818 4.6967L15.3033 3.51819L10 8.82149L4.6967 3.51819L3.51819 4.6967L8.82149 10L3.51819 15.3033L4.6967 16.4818L10 11.1785L15.3033 16.4818L16.4818 15.3033L11.1785 10L16.4818 4.6967Z" fill="#858585" />
            </g>
            <defs>
                <clipPath id="clip0_963_18">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export const SvgSettings = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="17" height="20" viewBox="0 0 17 18"><g clipPath="url(#clip0_1329_1622)">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.02247 0.818359C7.61537 0.818359 7.2702 1.11776 7.21268 1.52084L6.90885 3.64704C6.33417 3.86623 5.80383 4.17532 5.33501 4.55712L3.34029 3.75645C2.96236 3.60479 2.5305 3.75404 2.32696 4.10667L1.40031 5.71186C1.19656 6.06448 1.28325 6.51313 1.60366 6.76453L3.29375 8.09121C3.24661 8.38724 3.22204 8.69085 3.22204 9.00018C3.22204 9.30951 3.24661 9.6131 3.29375 9.90913L1.60366 11.2358C1.28325 11.4872 1.19656 11.9359 1.40031 12.2885L2.32696 13.8937C2.5305 14.2463 2.96236 14.3956 3.34029 14.2439L5.33501 13.4432C5.80383 13.825 6.33417 14.1341 6.90885 14.3533L7.21268 16.4795C7.2702 16.8826 7.61537 17.182 8.02247 17.182H9.87616C10.2833 17.182 10.6284 16.8826 10.686 16.4795L10.9898 14.3533C11.5645 14.1341 12.0948 13.8251 12.5636 13.4433L14.5581 14.2439C14.9361 14.3956 15.3679 14.2463 15.5715 13.8937L16.4983 12.2885C16.7019 11.9359 16.6152 11.4872 16.295 11.2358L14.6049 9.9092C14.652 9.61314 14.6766 9.30952 14.6766 9.00018C14.6766 8.89915 14.674 8.79874 14.6688 8.699C14.6582 8.49333 14.6366 8.29054 14.6049 8.09116L16.295 6.76453C16.6152 6.51313 16.7019 6.06448 16.4983 5.71186L15.5715 4.10667C15.3679 3.75404 14.9361 3.60479 14.5581 3.75645L12.5636 4.55707C12.0948 4.17529 11.5645 3.86623 10.9898 3.64704L10.686 1.52084C10.6284 1.11776 10.2833 0.818359 9.87616 0.818359H8.02247ZM8.9494 11.4547C10.305 11.4547 11.4039 10.3558 11.4039 9.00018C11.4039 7.64457 10.305 6.54563 8.9494 6.54563C7.59379 6.54563 6.49485 7.64457 6.49485 9.00018C6.49485 10.3558 7.59379 11.4547 8.9494 11.4547Z" fill="#5C5F62"></path></g><defs>
                <clipPath id="clip0_1329_1622"><rect width="16.3636" height="16.3636" fill="white" transform="translate(0.63623 0.818359)"></rect></clipPath></defs>
        </svg>
    )
}

export const SvgEdit = () => {
    return (
        <svg
            width="18px"
            height="18px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#2f59cb"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.8477 1.87868C19.6761 0.707109 17.7766 0.707105 16.605 1.87868L2.44744 16.0363C2.02864 16.4551 1.74317 16.9885 1.62702 17.5692L1.03995 20.5046C0.760062 21.904 1.9939 23.1379 3.39334 22.858L6.32868 22.2709C6.90945 22.1548 7.44285 21.8693 7.86165 21.4505L22.0192 7.29289C23.1908 6.12132 23.1908 4.22183 22.0192 3.05025L20.8477 1.87868ZM18.0192 3.29289C18.4098 2.90237 19.0429 2.90237 19.4335 3.29289L20.605 4.46447C20.9956 4.85499 20.9956 5.48815 20.605 5.87868L17.9334 8.55027L15.3477 5.96448L18.0192 3.29289ZM13.9334 7.3787L3.86165 17.4505C3.72205 17.5901 3.6269 17.7679 3.58818 17.9615L3.00111 20.8968L5.93645 20.3097C6.13004 20.271 6.30784 20.1759 6.44744 20.0363L16.5192 9.96448L13.9334 7.3787Z"
                    fill="#2f59cb"
                ></path>
            </g>
        </svg>
    );
};

export const SvgDelete = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                fill="#E03838"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                fill="#E03838"
            ></path>
        </svg>
    );
};

export const SvgBack = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="30" height="30" viewBox="0 0 30 30" fill="none">
            <rect x="29.75" y="29.75" width="29.5" height="29.5" rx="5.75" transform="rotate(180 29.75 29.75)" stroke="#666666" strokeWidth="0.5"></rect>
            <line x1="21" y1="15.043" x2="9" y2="15.043" stroke="#666666"></line>
            <path d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007" stroke="#666666" strokeWidth="1.2"></path>
        </svg>
    )
}