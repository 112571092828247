import React, { useCallback, useState, useEffect } from "react";
import { Button, Icon, Link, TextField } from "@shopify/polaris";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { BgThemeButton, BgThemeButtonFullWidth } from "../UI/Buttons";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { useDispatch, useSelector } from "react-redux";
import { saveLoginToken } from "../../reduxToolkit/Slice/loginTokenSlice";
import {
  checkIsAuthenticatedFalse,
  checkIsAuthenticatedTrue,
} from "../../reduxToolkit/Slice/isAuthenticatedSlice";

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [emailEntered, setEmailEntered] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);
  const [forgotPasswordPage, setForgotPasswordPage] = useState(false);
  const [validatePassword, setValidatePassword] = useState(false);
  // const [userToken, setUserToken] = useState()

  localStorage.clear();

  const emailToPasswordHandler = () => {
    if (validateEmailFormat(email)) {
      setIsEmailValid(true);
      setEmailEntered(true);
    } else {
      setIsEmailValid(false);
    }
  };
  const userToken = useSelector((state) => state.loginToken.value);

  useEffect(() => {
    localStorage.setItem("userToken", JSON.stringify(userToken));
  }, [userToken]);

  const validateEmailFormat = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const submitUserData = (e) => {
    e.preventDefault();
    const credentials = { email: email, password: password };
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/login`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        if (data.success) {
          dispatch(saveLoginToken(data.data.token));
          showSuccessToast(data.message);
          setEmail("");
          setPassword("");
          navigate("/dashboard");
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    localStorage.removeItem("userToken");
  }, []);

  const changeEmailHandler = useCallback(() => setEmailEntered(false), []);
  return (
    <>
      <div className="login-screen-container">
        <div className="login-full-container">
          <form onSubmit={(e) => submitUserData(e)} style={{width:"100%"}}>
            {!emailEntered ? (
              <>
                <div>
                  <img className="login-logo" src={logo} alt="logo" />
                </div>
                <div className="login-text-below-logo">
                  <h3>Login</h3>
                  <p>Continue to RevCatalyst</p>
                </div>
                <div>
                  <VerticalInputFieldCustom
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                    required={true}
                    titleName={"Email"}
                  />
                  {!isEmailValid && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginTop: "5px",
                      }}
                    >
                      Invalid email format
                    </div>
                  )}
                </div>
                <div className="continue-with-email-button">
                  <BgThemeButtonFullWidth
                    style={{ width: "100%" }}
                    onClick={emailToPasswordHandler}
                    children={"Continue With Email"}
                  />
                </div>

                {/* <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  New to RevCatalyst? <Link url="/signup">Get Started</Link>
                </div> */}
              </>
            ) : (
              <>
                <div>
                  <img className="login-logo" src={logo} alt="logo" />
                </div>
                <div className="login-text-below-logo">
                  <h3>
                    {forgotPasswordPage ? "Forget Your Password?" : "Login"}
                  </h3>
                  <p>
                    {forgotPasswordPage
                      ? "We’ll email instructions to example@gmail.com on how to reset it"
                      : "Continue to RevCatalyst"}
                  </p>
                </div>
                <div className="email-entered">
                  <div>{email}</div>
                  <div>
                    <Link onClick={changeEmailHandler}>Change Email</Link>
                  </div>
                </div>
                {forgotPasswordPage ? (
                  <>
                    <div className="continue-with-email-button">
                      <Button primary fullWidth={true}>
                        Reset Password
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <VerticalInputFieldCustom
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        className="textfield"
                        focused={false}
                        required={true}
                        autoComplete="off"
                        titleName={"Password"}
                        type={"password"}
                      />
                    </div>
                    <div className="continue-with-email-button">
                      <BgThemeButton type={"submit"} children={"Login"} />
                    </div>
                  </>
                )}
                {/* {forgotPasswordPage ? (
                  <>
                    <div
                      style={{
                        marginTop: "30px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Link onClick={() => setForgotPasswordPage(true)}>
                        Learn More
                      </Link>
                      <Link onClick={() => setForgotPasswordPage(false)}>
                        Return To Login
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ marginBottom: "35px" }}>
                      <Link onClick={() => setForgotPasswordPage(true)}>
                        Forgot Password?
                      </Link>
                    </div>
                  </>
                )} */}
              </>
            )}
            {/* <div
              className="login-privacy-buttons"
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <div>Help</div>
              <div>Privacy</div>
              <div>Terms</div>
            </div> */}
          </form>
        </div>
      </div>
    </>
  );
};
export default Login;
